import { Step, StepDescription, StepNumber, StepsSection } from "../reusable.styles"

import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import TwoCol_ImageContentSection from "../components/sections/TwoCol_ImageContentSection"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const DigitalEvents = ({ location }) => {

  const valueProps = [
    {
      headline:"Create temporary budgets",
      subheadline:"Each attendee has a set budget and rules for ordering meals.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001198/Grubhub%20Corporate%20Website/2021/Illustrations/F-DigitalEvents/EXPORTS-Illustrations-DigitalEvents-Icon1-Computer.png",
    },
    {
      headline:"Feed guests nationwide",
      subheadline:"Choose from over 300,000 restaurants in over 4,000 U.S. cities in all 50 states.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235127/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Hybrid%20Teams/EXPORT-HybridTeams-3.png",
    },
    {
      headline:"Consolidate orders to one invoice",
      subheadline:"Get a single invoice for food expenses.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/F-DigitalEvents/EXPORTS-Illustrations-DigitalEvents-Icon3-Invoice-Citrus.png",
    },
  ]

  const cards = [
    {
      title: "Remote Employees",
      description: "Show your remote team you care with customizable meal plans from Grubhub.",
      link: "/meals-for-remote-employees/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797391/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-RemoteEmployees.jpg",
      linkText: "Learn more"
    },
    {
      title: "Corporate Perks",
      description: "Discover how Grubhub Corporate can fit into your employee benefits package.",
      link: "/perks/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-CorporatePerks.jpg",
      linkText: "Learn more"
    },
    {
      title: "Hybrid Teams",
      description: "Keep your hungry teams happy, whether they're in the office, at home or both.",
      link:"/hybrid-teams/",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
      linkText: "Learn more"
    }
  ]

  const faqs = [
    {
      header: "What is a virtual lunch and learn?",
      description: "<span>A <a href='/blog/how-to-host-a-virtual-lunch-and-learn/'>virtual lunch and learn</a> is an effective way to foster connections with any audience virtually, including employees, sales prospects, and clients. As the name states, virtual lunch and learns are all about the food. A Grubhub Corporate Account can help you provide lunch to attendees, no matter where they’re dialing in from, while making it easy to stick to your budget.</span>"
    },
    {
      header: "How do you make virtual team meetings fun?",
      description: "<span>To make virtual team meetings fun you can host <a href='/blog/virtual-team-building-activities-for-remote-teams/'>virtual team-building activities for remote teams</a>. Digital activities like coffee chats, remote team trivia, and online yoga can help employees get to know each other better.</span>"
    }
  ]

  return (
    <Layout
      location={location}
      title={"Food Delivery for Virtual Meetings | Grubhub Corporate Accounts"}
      description={"From offering meals at lunch and learns to feeding your webinars, a Grubhub Corporate Account can provide food delivery for virtual meetings."}
      className="digital-events-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ordering occasions",
        pageName: "digital events",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Digital Events"
        subheadline="Whether you're meeting new clients, hosting a webinar or regrouping with your team, food brings everyone to the table."
        textColor="var(--color-secondary)"
        background="rgba(232,226,255,0.4)"
        ctaLink="/get-started/"
        ctaText="Get started"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796712/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-DigitalEvents.jpg"
        alt="A female sitting at her desk wearing headphones on a video conferencing call."
      />
      <ValuePropsSection
        headline="Save on the meals your virtual attendees crave"
        valueProps={valueProps}
        layout="vertical"
      />
      <TwoCol_ImageContentSection //eslint-disable-line
        imageAlignment="right"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797840/Grubhub%20Corporate%20Website/2021/Photography%20Elements/3-Subpage-CircleImages/EXPORTS-IMAGE-Circle-DigitalEvents.png"
        alt="A brown food bag with a receipt attached alongside an open salad box as well as a closed box with a fork and coffee on top."
        headline="How virtual meetings work"
        backgroundColor="rgba(232,226,255,0.2)"
      >
        <StepsSection>
          <Step>
            <StepNumber>Step 1</StepNumber>
            <StepDescription>Create a temporary budget based on your needs</StepDescription>
          </Step>
          <Step>
            <StepNumber>Step 2</StepNumber>
            <StepDescription>Upload meal credit recipients and tell them about their credit</StepDescription>
          </Step>
          <Step>
            <StepNumber>Step 3</StepNumber>
            <StepDescription>Attendees order delivery directly to their homes</StepDescription>
          </Step>
        </StepsSection>
      </TwoCol_ImageContentSection>
      <QuoteSection
        quote='"Grubhub has increased attendance for virtual prospect and customer webinars by 120%."'
        author="Key Chong"
        position="Enterprise Account Executive at AppZen"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Appzen.png"
        alt="Appzen logo"
        backgroundColor="white"
      />
      <CardsSection backgroundColor="rgba(232,226,255,0.2)" headline="More ordering occasions and information" cards={cards} chunkCards cardsPerRow={3} isSliderOnMobile/>
      <CtaSection headline="See what we can do for you" backgroundColor="white"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="#F9F9F9"/>
    </Layout>
  )
}

export default DigitalEvents
